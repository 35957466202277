import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import XeroInvoice from '../models/XeroInvoice';
import Table from '../components/Table';
import formatDate from '../utilities/dateFormat';

function ContactsPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.invoices;

  const columns = useMemo<MRT_ColumnDef<XeroInvoice>[]>(
    () => [
      {
        accessorKey: 'number',
        header: 'Number',
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
      },
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'reference',
        header: 'Reference',
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        accessorKey: 'amount_due',
        header: 'Amount Due',
      },
      {
        accessorKey: 'amount_paid',
        header: 'Amount Paid',
      },
      {
        accessorKey: 'amount_credited',
        header: 'Amount Credited',
      },
      {
        accessorKey: 'date',
        header: 'Date',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
      },
      {
        accessorKey: 'line_amount_types',
        header: 'Line Amount Types',
      },
      {
        accessorKey: 'sub_total',
        header: 'Sub Total',
      },
      {
        accessorKey: 'total_tax',
        header: 'Total Tax',
      },
      {
        accessorKey: 'total',
        header: 'Total',
      },
      {
        accessorKey: 'currency_code',
        header: 'Currency Code',
      },
      {
        accessorKey: 'updated_date',
        header: 'Updated Date',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
      },
      {
        accessorKey: 'xero_contact_correlation_id',
        header: 'Xero Contact Correlation ID',
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default ContactsPage;
