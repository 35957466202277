import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login/loginSlice';
import { saveState, loadState } from './storage';
import xeroDataReducer from './xeroData/xeroDataSlice';

const store = configureStore({
  reducer: {
    login: loginReducer,
    data: xeroDataReducer,
    // data: dataReducer,
    // pnl: pnlReducer,
  },
  preloadedState: loadState(),
});

store.subscribe(() => {
  saveState(store.getState());
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
