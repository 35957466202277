import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  height: 56px;
  background-color: #16a085;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0.27px;
  line-height: 26px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

const LoginButton = () => {
  return <Button>Log In</Button>;
};

export default LoginButton;
