import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  email: string | null;
  token: string | null;
  error: object | null;
}

const initialState: UserState = {
  email: null,
  token: null,
  error: null,
};

const extractEmail = (jwt: string): string => {
  const decoded: { user_id: string } = JSON.parse(atob(jwt.split('.')[1]));
  return decoded.user_id;
};

export const userSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ access: string; refresh: string }>) => {
      state.token = action.payload.access;
      state.email = extractEmail(action.payload.access);
      state.error = null;
    },
    setLoginError(state, action: PayloadAction<object>) {
      state.error = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.email = null;
    },
  },
});

export const { login, logout, setLoginError } = userSlice.actions;

export default userSlice.reducer;
