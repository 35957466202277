import dateformat from 'dateformat';

const formatDate = (date?: string): string => {
  if (!date) {
    return '';
  }
  return dateformat(new Date(date), 'dd/mm/yy HH:MM');
};

export default formatDate;
