import React, { useState } from 'react';
import { ReactReduxContext } from 'react-redux';
// import { MRT_ColumnDef } from 'material-react-table';
// import formatDate from '../utilities/dateFormat';
// import Table from '../components/Table';
// import XeroPnl from '../models/XeroPnl';

import { PivotTableUI, TableRenderers } from '@imc-trading/react-pivottable';

import '@imc-trading/react-pivottable/pivottable.css';

const storeToData = (pnlData: any) => {
  const v0 = pnlData[0];
  const keys = Object.keys(v0);
  const values: string[][] = [];
  for (let i = 0; i < pnlData.length; i += 1) {
    const v = pnlData[i];
    const vals: string[] = [];
    for (let j = 0; j < keys.length; j += 1) {
      const k = keys[j];
      vals.push(v[k]);
    }
    /* eslint-disable */
    values.push(vals);
  }

  return [keys, ...values];
};

function PnlPage() {
  const { store } = React.useContext(ReactReduxContext);

  const storeData = store.getState().data.pnl;
  const d = storeToData(storeData);

  const [pivottableState, setPivottableState] = useState({
    cols: ['month_year'],
    rows: ['pnl_account_type', 'account_name'],
    aggregatorName: 'Sum',
    vals: ['net'],
  });

  // const columns = useMemo<MRT_ColumnDef<XeroPnl>[]>(
  //   () => [
  //     {
  //       accessorKey: 'line_id',
  //       header: 'Line ID',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'date',
  //       header: 'Date',
  //       Cell: ({ cell }) => formatDate(cell.getValue<string>()),
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'source_type_level1',
  //       header: 'Src Type lvl. 1',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'number',
  //       header: 'Number',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'reference',
  //       header: 'Reference',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'status',
  //       header: 'Status',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'original_total',
  //       header: 'Orig Total',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'original_currency_code',
  //       header: 'Orig Curr. Code',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'pnl_account_type',
  //       header: 'Pnl Acc. Type',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'account_code',
  //       header: 'Account Code',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'account_name',
  //       header: 'Account Name',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'net',
  //       header: 'Net',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'contact',
  //       header: 'Contact',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'source_link',
  //       header: 'Source Link',
  //       size: 200,
  //     },
  //     {
  //       accessorKey: 'description',
  //       header: 'Description',
  //       size: 200,
  //     },
  //   ],
  //   []
  // );

  // return <Table data={data} columns={columns} />;

  return (
    <PivotTableUI
      data={d}
      onChange={(s) => setPivottableState(s)}
      renderers={{ ...TableRenderers }}
      {...pivottableState}
    />
  );
}

export default PnlPage;
