import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import formatDate from '../utilities/dateFormat';
import XeroContact from '../models/XeroContact';
import Table from '../components/Table';

function ContactsPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.contacts;

  const columns = useMemo<MRT_ColumnDef<XeroContact>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 200,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 20,
      },
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'updated_date',
        header: 'Updated Date',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 200,
      },
      {
        accessorFn: (originalRow) => (originalRow.is_supplier ? 'true' : 'false'),
        header: 'Is Supplier',
        filterVariant: 'checkbox',
        size: 20,
      },
      {
        accessorKey: 'is_customer',
        header: 'Is Customer',
        size: 20,
      },
      {
        accessorKey: 'number',
        header: 'Number',
        size: 20,
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default ContactsPage;
