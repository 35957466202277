import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import XeroContact from '../../models/XeroContact';
import XeroInvoice from '../../models/XeroInvoice';
import XeroJournal from '../../models/XeroJournal';
import XeroPayment from '../../models/XeroPayment';
import XeroLineItem from '../../models/XeroLineItem';
import XeroCreditNote from '../../models/XeroCreditNote';
import XeroJournalLine from '../../models/XeroJournalLine';
import XeroBankTransaction from '../../models/XeroBankTransaction';
import { XeroDataResponse } from '../../services/TDApi';
import XeroPnl from '../../models/XeroPnl';

interface XeroDataState {
  contacts: Array<XeroContact>;
  invoices: Array<XeroInvoice>;
  journals: Array<XeroJournal>;
  payments: Array<XeroPayment>;
  lineItems: Array<XeroLineItem>;
  creditNotes: Array<XeroCreditNote>;
  journalLines: Array<XeroJournalLine>;
  bankTransactions: Array<XeroBankTransaction>;
  pnl: Array<XeroPnl>;
}

const initialState: XeroDataState = {
  contacts: [],
  invoices: [],
  journals: [],
  payments: [],
  lineItems: [],
  creditNotes: [],
  journalLines: [],
  bankTransactions: [],
  pnl: [],
};

export const xeroDataSlice = createSlice({
  name: 'xeroData',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<XeroDataResponse>) => {
      state.contacts = action.payload.contacts;
      state.invoices = action.payload.invoices;
      state.journals = action.payload.journals;
      state.payments = action.payload.payments;
      state.lineItems = action.payload.line_items;
      state.creditNotes = action.payload.credit_notes;
      state.journalLines = action.payload.journal_lines;
      state.bankTransactions = action.payload.bank_transactions;
    },
    setPnl: (state, action: PayloadAction<Array<XeroPnl>>) => {
      state.pnl = action.payload;
    },
  },
});

export const { setData, setPnl } = xeroDataSlice.actions;

export default xeroDataSlice.reducer;
