import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import formatDate from '../utilities/dateFormat';
import Table from '../components/Table';
import XeroCreditNote from '../models/XeroCreditNote';

function CreditNotesPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.creditNotes;

  const columns = useMemo<MRT_ColumnDef<XeroCreditNote>[]>(
    () => [
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'xero_invoice_correlation_id',
        header: 'Xero Invoice Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'number',
        header: 'Number',
        size: 200,
      },
      {
        accessorKey: 'reference',
        header: 'Reference',
        size: 200,
      },
      {
        accessorKey: 'remaining_credit',
        header: 'Remaining Credit',
        size: 200,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 200,
      },
      {
        accessorKey: 'has_attachments',
        header: 'Has Attachments',
        size: 200,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 200,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 200,
      },
      {
        accessorKey: 'line_amount_types',
        header: 'Line Amount Types',
        size: 200,
      },
      {
        accessorKey: 'sub_total',
        header: 'Sub Total',
        size: 200,
      },
      {
        accessorKey: 'total_tax',
        header: 'Total Tax',
        size: 200,
      },
      {
        accessorKey: 'total',
        header: 'Total',
        size: 200,
      },
      {
        accessorKey: 'currency_code',
        header: 'Currency Code',
        size: 200,
      },
      {
        accessorKey: 'updated_date',
        header: 'Updated Date',
        size: 200,
      },
      {
        accessorKey: 'paid_date',
        header: 'Paid Date',
        size: 200,
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default CreditNotesPage;
