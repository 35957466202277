import XeroContact from '../models/XeroContact';
import XeroInvoice from '../models/XeroInvoice';
import XeroJournal from '../models/XeroJournal';
import XeroBankTransaction from '../models/XeroBankTransaction';
import XeroLineItem from '../models/XeroLineItem';
import XeroCreditNote from '../models/XeroCreditNote';
import XeroJournalLine from '../models/XeroJournalLine';
import XeroPayment from '../models/XeroPayment';
import XeroPnl from '../models/XeroPnl';

enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const baseURL = 'https://td-api.herokuapp.com'; // 'http://localhost:8888';

export type XeroDataResponse = {
  contacts: XeroContact[];
  invoices: XeroInvoice[];
  journals: XeroJournal[];
  bank_transactions: XeroBankTransaction[];
  line_items: XeroLineItem[];
  credit_notes: XeroCreditNote[];
  journal_lines: XeroJournalLine[];
  payments: XeroPayment[];
};

class TDApi {
  token: string;

  unauthCallback?: () => void;

  constructor(token: string, unauthorizedCallback?: () => void) {
    this.token = token;
    this.unauthCallback = unauthorizedCallback;
  }

  private getHeaders() {
    return {
      Authorization: `JWT ${this.token}`,
      'Content-Type': 'application/json',
    };
  }

  private async request(path: string, method: HTTPMethod, body?: object): Promise<object> {
    const response = await fetch(`${baseURL}${path}`, {
      method,
      headers: this.getHeaders(),
      body: body ? JSON.stringify(body) : null,
    });

    if ([401, 403].includes(response.status)) {
      this.unauthCallback?.();
    }

    return response.json();
  }

  async getUserInfo() {
    return this.request('/api/v1/users/me/', HTTPMethod.GET);
  }

  async getXeroContacts() {
    const response: { results?: Array<object> } = await this.request(
      '/api/v1/xero/contacts/',
      HTTPMethod.GET
    );
    return response.results;
  }

  async getXeroInvoices() {
    const response: { results?: Array<object> } = await this.request(
      '/api/v1/xero/invoices/',
      HTTPMethod.GET
    );
    return response.results;
  }

  async getXeroJournals() {
    const response: { results?: Array<object> } = await this.request(
      '/api/v1/xero/journals/',
      HTTPMethod.GET
    );
    return response.results;
  }

  async getXeroData(): Promise<XeroDataResponse> {
    const response: XeroDataResponse = (await this.request(
      '/api/v1/xero/data/',
      HTTPMethod.GET
    )) as XeroDataResponse;
    return response;
  }

  async getXeroPnl(): Promise<XeroPnl[]> {
    const response: XeroPnl[] = (await this.request(
      '/api/v1/xero/pnl/',
      HTTPMethod.GET
    )) as XeroPnl[];
    return response;
  }
}

export default TDApi;
