import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1.5s linear infinite;
  transform: translateZ(0);

  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #14a085;
  border-radius: 50%;
`;

export default Spinner;
