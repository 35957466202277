import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import formatDate from '../utilities/dateFormat';
import Table from '../components/Table';
import XeroJournalLine from '../models/XeroJournalLine';

function JournalLinesPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.journalLines;

  const columns = useMemo<MRT_ColumnDef<XeroJournalLine>[]>(
    () => [
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'account_id',
        header: 'Account ID',
        size: 200,
      },
      {
        accessorKey: 'account_code',
        header: 'Account Code',
        size: 200,
      },
      {
        accessorKey: 'account_type',
        header: 'Account Type',
        size: 200,
      },
      {
        accessorKey: 'account_name',
        header: 'Account Name',
        size: 200,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 200,
      },
      {
        accessorKey: 'net_amount',
        header: 'Net Amount',
        size: 200,
      },
      {
        accessorKey: 'gross_amount',
        header: 'Gross Amount',
        size: 200,
      },
      {
        accessorKey: 'tax_amount',
        header: 'Tax Amount',
        size: 200,
      },
      {
        accessorKey: 'tax_type',
        header: 'Tax Type',
        size: 200,
      },
      {
        accessorKey: 'tax_name',
        header: 'Tax Name',
        size: 200,
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default JournalLinesPage;
