import React, { useState } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: ${(props) => props.width};
  height: 50px;
  padding: 5px 25px;
  font-size: 1em;
  font-weight: 500;
  color: #3d3b48;
  border-radius: 5px;
  border: 1px #dedede solid;

  &:valid {
    border: 2px #38cc8b solid;
  }

  &:focus {
    outline: 2px solid transparent;
  }

  &:valid {
    border: 2px #38cc8b solid;
  }

  &:focus {
    outline: 2px solid transparent;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 85%;
`;

const InputField = (props: any) => {
  const [focused, setFocused] = useState(false);
  const { id, errMsg, onChange, ...inputData } = props;

  const focusHandler = () => {
    setFocused(true);
  };

  return (
    <Wrapper>
      <Input {...inputData} onChange={onChange} onBlur={focusHandler} focused={focused} />
    </Wrapper>
  );
};

export default InputField;
