import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import formatDate from '../utilities/dateFormat';
import Table from '../components/Table';
import XeroBankTransaction from '../models/XeroBankTransaction';

function BankTransactionsPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.bankTransactions;

  const columns = useMemo<MRT_ColumnDef<XeroBankTransaction>[]>(
    () => [
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'xero_bank_account_correlation_id',
        header: 'Xero Bank Account Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'bank_account_code',
        header: 'Bank Account Code',
        size: 200,
      },
      {
        accessorKey: 'bank_account_name',
        header: 'Bank Account Name',
        size: 200,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 200,
      },
      {
        accessorKey: 'reference',
        header: 'Reference',
        size: 200,
      },
      {
        accessorKey: 'is_reconciled',
        header: 'Is Reconciled',
        size: 200,
      },
      {
        accessorKey: 'xero_contact_correlation_id',
        header: 'Xero Contact Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 200,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 200,
      },
      {
        accessorKey: 'line_amount_types',
        header: 'Line Amount Types',
        size: 200,
      },
      {
        accessorKey: 'sub_total',
        header: 'Sub Total',
        size: 200,
      },
      {
        accessorKey: 'total_tax',
        header: 'Total Tax',
        size: 200,
      },
      {
        accessorKey: 'total',
        header: 'Total',
        size: 200,
      },
      {
        accessorKey: 'updated_date',
        header: 'Updated Date',
        size: 200,
      },
      {
        accessorKey: 'currency_code',
        header: 'Currency Code',
        size: 200,
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default BankTransactionsPage;
