import React, { FC, Suspense } from 'react';
import styled from 'styled-components';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Spinner from './components/Spinner';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import Protected from './components/Protected';
import ContactsPage from './pages/Contacts';
import InvoicesPage from './pages/Invoices';
import JournalsPage from './pages/Journals';
import LineItemsPage from './pages/LineItems';
import PaymentsPage from './pages/Payments';
import CreditNotesPage from './pages/CreditNotes';
import BankTransactionsPage from './pages/BankTransactions';
import JournalLinesPage from './pages/JournalLines';
import PnlPage from './pages/Pnl';

const LayoutWrapper = styled.div`
  height: 100vh;
`;

const Layout: FC = () => {
  const location = useLocation();

  return (
    // https://www.robinwieruch.de/react-router-private-routes/
    <LayoutWrapper>
      <Suspense fallback={<Spinner />}>
        <Routes location={location}>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <Protected>
                <HomePage />
              </Protected>
            }
          />
          <Route
            path="/contacts"
            element={
              <Protected>
                <ContactsPage />
              </Protected>
            }
          />
          <Route
            path="/invoices"
            element={
              <Protected>
                <InvoicesPage />
              </Protected>
            }
          />
          <Route
            path="/journals"
            element={
              <Protected>
                <JournalsPage />
              </Protected>
            }
          />
          <Route
            path="/line-items"
            element={
              <Protected>
                <LineItemsPage />
              </Protected>
            }
          />
          <Route
            path="/payments"
            element={
              <Protected>
                <PaymentsPage />
              </Protected>
            }
          />
          <Route
            path="/credit-notes"
            element={
              <Protected>
                <CreditNotesPage />
              </Protected>
            }
          />
          <Route
            path="/bank-transactions"
            element={
              <Protected>
                <BankTransactionsPage />
              </Protected>
            }
          />
          <Route
            path="/journal-lines"
            element={
              <Protected>
                <JournalLinesPage />
              </Protected>
            }
          />
          <Route
            path="/pnl"
            element={
              <Protected>
                <PnlPage />
              </Protected>
            }
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Suspense>
    </LayoutWrapper>
  );
};

export default Layout;
