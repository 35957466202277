import React, { ReactElement, Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Spinner from './components/Spinner';
import Layout from './Layout';

function Router(): ReactElement {
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Layout />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
