import React from 'react';
import styled from 'styled-components';

export interface NavigationCardProps {
  title: string;
  icon: React.ReactNode;
  tint: string;
  onClick?: () => void;
}

const Wrapper = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  gap: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
`;

const Icon = styled.div`
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
`;

const ResizableText = styled.div`
  font-size: 1em;
  text-align: center;
`;

const NavigationCard = (props: NavigationCardProps) => {
  return (
    <Wrapper style={{ color: props.tint }} onClick={props.onClick}>
      <Icon>{props.icon}</Icon>
      <ResizableText>{props.title}</ResizableText>
    </Wrapper>
  );
};

export default NavigationCard;
