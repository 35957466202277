import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import InputField from './InputField';
import LoginButton from './LoginButton';
import { useAppDispatch } from '../store/hooks';
import { login, setLoginError } from '../store/login/loginSlice';
import { baseURL } from '../services/TDApi';
import Spinner from './Spinner';

export const Form = styled.form`
  width: 500px;
  height: 300px;
  min-width: 400px;
  min-height: 300px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 20px 0px;
  margin-top: -100px;

  /* LAPTOP */
  @media only screen and (max-width: 1115px) {
    width: 100%;
  }
`;
export const Wrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: center;
  align-items: center;
`;

const OuterContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;     /* center items ho
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginForm = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const data = [
    {
      id: 1,
      type: 'email',
      name: 'email',
      placeholder: 'Email Address',
      required: true,
      errMsg: 'Looks like this is not an email',
      pattern:
        '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-z]{2,}|.[0-9]{1,})$',
    },
    {
      id: 2,
      type: 'password',
      name: 'password',
      placeholder: 'Password',
      required: true,
      errMsg: 'Password cannot be empty',
      pattern: '^[A-Za-z0-9]{1,}$',
    },
  ];

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowSpinner(true);
    try {
      const result = await fetch(`${baseURL}/api/v1/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (result.ok) {
        const loginData = await result.json();
        dispatch(login(loginData));
        navigate('/');
      } else {
        setShowSpinner(false);
        const error = await result.json();
        dispatch(setLoginError(error));
      }
    } catch (err) {
      dispatch(setLoginError(err as Error));
    }
  };

  const changeHandler = (
    e: React.FormEvent & {
      target: HTMLButtonElement;
    }
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <OuterContainer>
      <InnerContainer>
        {showSpinner ? (
          <Spinner />
        ) : (
          <Form onSubmit={submitHandler}>
            {data.map((input) => {
              return <InputField key={input.id} {...input} onChange={changeHandler} />;
            })}
            <Wrapper>
              <LoginButton />
            </Wrapper>
          </Form>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};

export default LoginForm;
