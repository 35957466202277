import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContactCard } from 'styled-icons/fa-solid';
import { LineStyle, Payments } from 'styled-icons/material';
import { CreditCard2FrontFill, JournalText, Journals } from 'styled-icons/bootstrap';
import { BuildingBank } from 'styled-icons/fluentui-system-filled';
import { Report } from 'styled-icons/boxicons-solid';
import { Bill } from 'styled-icons/remix-fill';
import { ReactReduxContext } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import NavigationCard from '../components/NavigationCard';
import FullPageContainer from '../components/FullPageContainer';
import Spinner from '../components/Spinner';
import TDApi from '../services/TDApi';
import { useAppDispatch } from '../store/hooks';
import { logout } from '../store/login/loginSlice';
import { setData, setPnl } from '../store/xeroData/xeroDataSlice';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 0.1fr);
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
`;

const Header = styled.div`
  font-size: 3em;
  font-weight: 600;
  margin: 50px;
  text-align: center;
  color: #fff;
`;

function HomePage() {
  const { store } = React.useContext(ReactReduxContext);
  const dispatch = useAppDispatch();
  const { email, token } = store.getState().login;
  const [showSpinner, setShowSpinner] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAll = async () => {
      const api = new TDApi(token, () => dispatch(logout()) && navigate('/login'));
      const [data, pnl] = await Promise.all([api.getXeroData(), api.getXeroPnl()]);
      return { data, pnl };
    };

    fetchAll()
      .then(({ data, pnl }) => {
        if (data) {
          dispatch(setData(data));
        }
        if (pnl) {
          dispatch(setPnl(pnl));
        }
        setShowSpinner(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  });

  return (
    <FullPageContainer>
      <Header>Welcome, {email}.</Header>
      {showSpinner ? (
        <Spinner />
      ) : (
        <Grid>
          <NavigationCard
            title="Contacts"
            icon={<ContactCard />}
            tint="#14A085"
            onClick={() => navigate('/contacts')}
          />
          <NavigationCard
            title="Invoices"
            icon={<Bill />}
            tint="#14A085"
            onClick={() => navigate('/invoices')}
          />
          <NavigationCard
            title="Line Items"
            icon={<LineStyle />}
            tint="#14A085"
            onClick={() => navigate('/line-items')}
          />
          <NavigationCard
            title="Payments"
            icon={<Payments />}
            tint="#14A085"
            onClick={() => navigate('/payments')}
          />
          <NavigationCard
            title="Credit Notes"
            icon={<CreditCard2FrontFill />}
            tint="#14A085"
            onClick={() => navigate('/credit-notes')}
          />
          <NavigationCard
            title="Bank Transactions"
            icon={<BuildingBank />}
            tint="#14A085"
            onClick={() => navigate('/bank-transactions')}
          />
          <NavigationCard
            title="Journals"
            icon={<Journals />}
            tint="#14A085"
            onClick={() => navigate('/journals')}
          />
          <NavigationCard
            title="Journal Lines"
            icon={<JournalText />}
            tint="#14A085"
            onClick={() => navigate('/journal-lines')}
          />
          <NavigationCard
            title="P&L"
            icon={<Report />}
            tint="#14A085"
            onClick={() => navigate('/pnl')}
          />
        </Grid>
      )}
    </FullPageContainer>
  );
}

export default HomePage;
