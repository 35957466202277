import React, { ReactElement } from 'react';
import { ReactReduxContext } from 'react-redux';
import { Navigate } from 'react-router-dom';

const Protected = (props: { children: ReactElement }): ReactElement => {
  const { store } = React.useContext(ReactReduxContext);
  const isAuthenticated = store.getState().login.token !== null;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return props.children;
};

export default Protected;
