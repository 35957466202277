import React from 'react';
import { ReactReduxContext } from 'react-redux';
import { Navigate } from 'react-router-dom';

import LoginForm from '../components/LoginForm';
import FullPageContainer from '../components/FullPageContainer';

function LoginPage() {
  const { store } = React.useContext(ReactReduxContext);
  const isAuthenticated = store.getState().login.token !== null;
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return (
    <FullPageContainer>
      <LoginForm />
    </FullPageContainer>
  );
}

export default LoginPage;
