import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import formatDate from '../utilities/dateFormat';
import Table from '../components/Table';
import XeroPayment from '../models/XeroPayment';

function PaymentsPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.payments;

  const columns = useMemo<MRT_ColumnDef<XeroPayment>[]>(
    () => [
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'batch_payment_id',
        header: 'Batch Payment ID',
        size: 200,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 200,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        size: 200,
      },
      {
        accessorKey: 'reference',
        header: 'Reference',
        size: 200,
      },
      {
        accessorKey: 'has_account',
        header: 'Has Account',
        size: 200,
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default PaymentsPage;
