import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import XeroJournal from '../models/XeroJournal';
import Table from '../components/Table';
import formatDate from '../utilities/dateFormat';

function JournalsPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.journals;

  const columns = useMemo<MRT_ColumnDef<XeroJournal>[]>(
    () => [
      {
        accessorKey: 'number',
        header: 'Number',
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
      },
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'reference',
        header: 'Reference',
      },
      {
        accessorKey: 'source_id',
        header: 'Source ID',
      },
      {
        accessorKey: 'source_type',
        header: 'Source Type',
      },
      {
        accessorKey: 'date',
        header: 'Date',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default JournalsPage;
