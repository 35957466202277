import React, { ReactNode } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';

export type TableProps<TData extends Record<string, ReactNode>> = {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
};

function Table<TData extends Record<string, any>>(props: TableProps<TData>) {
  const { columns, data } = props;

  const csvOptions = {
    filename: 'export',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows: MRT_Row<TData>[]) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      renderTopToolbarCustomActions={({ table }) => (
        <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
          <Button
            style={{ backgroundColor: '#2c3e50', color: 'white', marginLeft: '-15px' }}
            disabled={table.getRowModel().rows.length === 0}
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Visible Rows
          </Button>
        </Box>
      )}
      renderToolbarInternalActions={() => (
        <>
          <div />
          <div />
        </>
      )}
      enableColumnOrdering
      initialState={{
        density: 'compact',
        pagination: { pageIndex: 0, pageSize: 100 },
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [50, 100, 200, 300, 400, 500],
      }}
      enableTableFooter
      enableStickyHeader
      enablePagination
      enableRowSelection={false}
      enableFilters
      enableColumnDragging={false}
      enableClickToCopy
      enablePinning
      muiTableBodyCellProps={{
        sx: {
          border: '1px solid #2c3e50',
          alignContent: 'center',
          alignItems: 'center',
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          border: '1px solid white',
          color: 'white',
          backgroundColor: '#2c3e50',
          fontWeight: 'bold',
          fontSize: '18px',
          height: '25px',
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
          '& .MuiInputBase-input': {
            color: 'white',
          },
          '& .MuiFormControlLabel-root': {
            color: '#89929C',
          },
        },
      }}
    />
  );
}

export default Table;
