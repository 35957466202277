import React, { useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import formatDate from '../utilities/dateFormat';
import Table from '../components/Table';
import XeroLineItem from '../models/XeroLineItem';

function LineItemsPage() {
  const { store } = React.useContext(ReactReduxContext);

  const data = store.getState().data.lineItems;

  const columns = useMemo<MRT_ColumnDef<XeroLineItem>[]>(
    () => [
      {
        accessorKey: 'correlation_id',
        header: 'Correlation ID',
        size: 200,
      },
      {
        accessorKey: 'imported_at',
        header: 'Imported At',
        Cell: ({ cell }) => formatDate(cell.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'item_code',
        header: 'Item Code',
        size: 200,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 200,
      },
      {
        accessorKey: 'unit_amount',
        header: 'Unit Amount',
        size: 200,
      },
      {
        accessorKey: 'tax_type',
        header: 'Tax Type',
        size: 200,
      },
      {
        accessorKey: 'tax_amount',
        header: 'Tax Amount',
        size: 200,
      },
      {
        accessorKey: 'line_amount',
        header: 'Line Amount',
        size: 200,
      },
      {
        accessorKey: 'account_code',
        header: 'Account Code',
        size: 200,
      },
      {
        accessorKey: 'item_id',
        header: 'Item ID',
        size: 200,
      },
      {
        accessorKey: 'item_name',
        header: 'Item Name',
        size: 200,
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        size: 200,
      },
    ],
    []
  );

  return <Table data={data} columns={columns} />;
}

export default LineItemsPage;
